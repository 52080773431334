import dayjs from 'dayjs';

import { NewIndicator } from 'components/elements/new-tag';

type Post = {
  title: string | undefined;
  subTitle: string;
  date: string;
  startDate: string;
  endDate: string;
};

export const GameScheduleCard = ({
  title,
  subTitle,
  date,
  startDate,
  endDate,
}: Post) => {
  return (
    <div className='flex hover:opacity-70 xs:hover:opacity-100'>
      <div className='block h-full'>
        <div className='text-sm underline line-clamp-1'>{subTitle}</div>
        <div className='mt-3 text-base font-bold underline line-clamp-3'>
          {title}
        </div>
        <div className='flex items-baseline gap-3 mt-3'>
          {/* <time className='text-[#8a8a8a] text-xs underline'>
            {dayjs(date).format('YYYY.MM.DD')}
          </time> */}
          <time className='text-[#8a8a8a] text-xs underline'>
            {dayjs(startDate).format('YYYY.MM.DD')} -{' '}
            {dayjs(endDate).format('YYYY.MM.DD')}
          </time>
          <div className='xs:absolute xs:bottom-3 xs:right-3'>
            <NewIndicator dateString={date} />
          </div>
        </div>
      </div>
    </div>
  );
};
