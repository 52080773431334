'use client';
import { useEffect, useRef } from 'react';

type VideoComponentProps = {
  url: string;
};

export const VideoComponent = ({ url }: VideoComponentProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef]);
  return (
    <video
      preload='none'
      autoPlay
      playsInline
      loop
      muted
      aria-label='Video player'
      ref={videoRef}
    >
      <source src={url} type='video/mp4' />
      Your browser does not support the video tag.
    </video>
  );
};
