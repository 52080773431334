import React from 'react';

interface Props {
  dateString: string;
}

export const NewIndicator: React.FC<Props> = ({ dateString }) => {
  // Date stringをUnixタイムスタンプに変換
  const date = new Date(dateString).getTime();
  const now = Date.now();
  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // 1週間以内かどうかをチェック
  const isNew = now - date < oneWeek;

  return (
    <div>
      {isNew && <span className='text-xs font-bold text-[#2cb4ad]'>new</span>}
    </div>
  );
};
