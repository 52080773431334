'use client';

import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/skyblue';
import Splide from '@splidejs/splide';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';
import noImage from '../../../../assets/images/logo.jpg';

import { Icons } from 'components/elements/icons';
import { NewIndicator } from 'components/elements/new-tag';
import { News } from 'infra/News';

type Props = {
  contents: News[];
};

export const SwiperNews = ({ contents }: Props) => {
  useEffect(() => {
    const splide = new Splide('#splide-news', {
      type: 'loop',
      drag: 'free',
      perPage: 3,
      breakpoints: {
        640: {
          destroy: true,
        },
      },
      classes: {
        arrows: 'splide__arrows standard-arrows',
        arrow: 'splide__arrow standard-arrow',
        prev: 'splide__arrow--prev standard-arrow-prev',
        next: 'splide__arrow--next standard-arrow-next',
      },
      autoScroll: {
        speed: 1,
      },
      pagination: false,
      gap: '24px',
    });

    splide.mount();
  }, []);

  return (
    <>
      <div
        className='max-w-[1260px] px-[3.75rem] splide news xs:!p-0'
        id='splide-news'
      >
        <div className='splide__track'>
          <div className='splide__list xs:items-start xs:!overflow-x-scroll xs:!flex xs:gap-5'>
            {contents.map((news) => {
              return (
                <div
                  className='splide__slide w-full !col-[auto] max-w-[268px]'
                  key={news.id}
                >
                  <Link href={`/news/${news.id}`}>
                    <div className='relative mt-3'>
                      <Image
                        src={news.image?.url || noImage}
                        alt={news.title}
                        width='384'
                        height='256'
                        className='block object-cover w-full h-64'
                      />
                      <div className='mt-4 overflow-hidden text-sm font-bold leading-5 line-clamp-2'>
                        {news.title}
                      </div>
                      <div className='mt-2 text-xs text-[#8a8a8a] flex items-center tracking-widest gap-3'>
                        <time className=''>
                          {dayjs(news.publishedAt).format('YYYY.MM.DD')}
                        </time>
                        <div className='xs:absolute xs:bottom-3 xs:right-3'>
                          <NewIndicator dateString={news.publishedAt} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className='splide__arrows splide__arrows--ltr standard-arrows xs:hidden'>
          <button
            className='splide__arrow splide__arrow--prev standard-arrow standard-arrow-prev !-left-6'
            type='button'
            aria-label='Previous slide'
          >
            <Icons.arrowPrev className='w-4 h-4' />
          </button>
          <button
            className='splide__arrow splide__arrow--next standard-arrow standard-arrow-next !-right-6'
            type='button'
            aria-label='Next slide'
          >
            <Icons.arrowNext className='w-4 h-4' />
          </button>
        </div>
      </div>
    </>
  );
};
