'use client';
import Splide from '@splidejs/splide';
import Link from 'next/link';
import { useEffect } from 'react';
import { GameScheduleCard } from './GameScheduleCard/GameScheduleCard';

import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/skyblue';
import { Icons } from 'components/elements/icons';
import { GameSchedule } from 'infra/GameSchedule';

type Props = {
  contents: GameSchedule[];
};

export const SwiperGameSchedule = ({ contents }: Props) => {
  useEffect(() => {
    const splide = new Splide('#splide-game', {
      type: 'loop',
      drag: 'free',
      perPage: 4,
      breakpoints: {
        640: {
          destroy: true,
          gap: '12px',
        },
      },
      classes: {
        arrows: 'splide__arrows standard-arrows',
        arrow: 'splide__arrow standard-arrow',
        prev: 'splide__arrow--prev standard-arrow-prev',
        next: 'splide__arrow--next standard-arrow-next',
      },
      autoScroll: {
        speed: 1,
      },
      pagination: false,
      gap: '42px',
    });

    splide.mount();
  }, []);

  return (
    <>
      <div
        className='max-w-[1260px] px-[3.75rem] splide game xs:!p-0'
        id='splide-game'
      >
        <div className='splide__track'>
          <div className='splide__list xs:items-start xs:!overflow-x-scroll xs:!flex xs:gap-5'>
            {contents.map((game) => {
              return (
                <div
                  className='splide__slide w-full !col-[auto] max-w-[268px] flex-shrink-0 xs:px-5 xs:py-4 xs:rounded-lg xs:bg-[#dee7e8] xs:flex xs:min-h-[164px]'
                  key={game.id}
                >
                  <Link href={game.link} className='block h-full'>
                    <GameScheduleCard
                      title={game.title}
                      subTitle={game.subTitle}
                      date={game.publishedAt}
                      startDate={game.startDate}
                      endDate={game.endDate}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className='splide__arrows splide__arrows--ltr standard-arrows xs:hidden'>
          <button
            className='splide__arrow splide__arrow--prev standard-arrow standard-arrow-prev !-left-6'
            type='button'
            aria-label='Previous slide'
          >
            <Icons.arrowPrev className='w-4 h-4' />
          </button>
          <button
            className='splide__arrow splide__arrow--next standard-arrow standard-arrow-next !-right-6'
            type='button'
            aria-label='Next slide'
          >
            <Icons.arrowNext className='w-4 h-4' />
          </button>
        </div>
      </div>
    </>
  );
};
